<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <page-header class="mb-3" :title="title + clienteNombre" />
    <v-data-table
      :headers="headers"
      :items="sitiosCliente"
      :single-expand="singleExpand"
      :search="search"
      :footer-props="{ 'items-per-page-text': 'Sitios por página' }"
      :expanded.sync="expanded"
      item-key="sitioSistemaClienteId"
      show-expand
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar sitio"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1">
            </v-col>
            <v-col cols="3" class="pt-5 text-right">
              <v-btn
                color="primary"
                @click="newItem()"
                class="text-right mr-2"
              >
                Nuevo
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
       <td :colspan="headers.length">
              <strong>Fecha alta:</strong>
              {{ item.fecAlta }}
              <strong style="padding-left: 80px">Usuario alta:</strong>
              {{ item.usuAlta }}
              <strong style="padding-left: 80px">Fecha modificación:</strong>
              {{ item.fecModi }}
              <strong style="padding-left: 80px">Usuario modificación:</strong>
              {{ item.usuModi }}
       </td>
        
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar sitio</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <span>Eliminar sitio</span>
        </v-tooltip>
      </template>
      
    </v-data-table>
    <v-dialog v-model="dialog" max-width="40%" @keydown.esc="close()" persistent>
      <v-card outlined>
        <v-card-title>
          <span class="py-1 primary--text">{{ formEditTitle }} {{this.clienteNombre}}</span>
        </v-card-title>
        <v-card-text class="pb-2">
          <v-form
            v-model="isFormValid"
            ref="form"
            form="form"
            id="form"
            @submit.prevent="save()"
          >
              <!-- Sistema -->
          <v-row>
               <v-col cols="12" sm="6" md="12" class="py-0">
              <v-autocomplete
                v-model="sistemaSelected"
                :items="sistemas"
                item-text="value"
                item-value="id"
                label="Sistema"
                :rules="[...rules.required]"
                outlined
                clearable
                autocomplete="off"
                dense
                @change="setSitiosSistema()"
              >
              </v-autocomplete>
            </v-col>
            <!-- Nombre sitio -->
            <v-col cols="12" sm="6" md="12" class="py-0">
              <v-autocomplete
                v-model="sitioSelected"
                :items="sitiosSistema"
                item-text="value"
                item-value="id"
                label="Nombre de sitio"
                :rules="[...rules.required]"
                outlined
                clearable
                autocomplete="off"
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Descripcion -->
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                v-model="descripcion"
                :rules="[...rules.required]"
                label="Descripción"
                dense
                auto-grow
                rows="2"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="close">
            <span align="center"> Cancelar </span>
          </v-btn>
          <v-btn
            color="#195472"
            type="submit"
            :disabled="!isFormValid"
            form="form"
          >
            <span class="myLabel" align="center">Guardar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      max-width="30%"
      @keydown.esc="dialogDelete = false"
    >
      <v-card>
        <v-card-title class="primary--text d-flex mb-1"
          >¿Eliminar sitio?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-left" text @click="closeDelete">Cancelar</v-btn>
          <v-btn text color="primary" dark @click="deleteItemConfirm"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, } from "vuex";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import rules from "@/utils/helpers/rules";

//import enumsAdmin from "@/utils/enums/modules/index.js";
import { mask } from "vue-the-mask";

export default {
  components: { PageHeader, GoBackBtn },
  directives: { mask },
  name: "SitiosSistemas",

  data() {
    return {
      rules: rules,
      isLoading: false,
      dialog: false,
      expanded: [],
      sitiosSistema: [],
      sistemas:[],
      isFormValid: false,
      sitiosCliente: [],
      formEditTitle: "Editar sitio del cliente: ",
      search: "",
      dialogDelete: false,
      routeToGo: "/clientes",
      singleExpand: true,
      // optionCode: enums.webSiteOptions.FECHA_CORTE,
      date: null,
      dateFormatted: null,
      menu: false,
      title: "Sitios ",
      headers: [
      
        { text: "Sitio", value: "sitioNombre", sortable: true },
        { text: "Sistema", sortable: false, value: "sistemaNombre"},
        { text: "Descripcion", value: "descripcion", sortable: true },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      clienteId: null,
      clienteNombre:null,
      descripcion:null,
      sitioSelected:null,
      sistemaSelected:null,
    };
  },
  // computed: {
  //   ...mapGetters({
  //     alert: "alert",
  //   }),
  // },
  mounted() {
    this.clienteId = this.$route.params.itemsClientes.clienteId
    this.clienteNombre = this.$route.params.itemsClientes.clienteNombre;
    this.loadSitios();
    this.setSistemas();
   //this.setSitiosSistema();
    
    // this.setPermisos();
  },

  methods: {
    ...mapActions({
      getSitiosbyCliente: "clientesData/getSitiosbyCliente",
      saveSitio: "clientesData/saveSitio",
      deleteSitio: "clientesData/deleteSitio",
      sitiosTaxonomy: "clientesData/sitiosTaxonomy",
      sistemasTaxonomy:"clientesData/sistemasTaxonomy",
      setAlert: "user/setAlert",
    }),
    async loadSitios() {
      this.isLoading = true;
      const responseaxios = await this.getSitiosbyCliente(this.clienteId);
      this.sitiosCliente = responseaxios;
      this.isLoading = false;
    },
    async setSitiosSistema(){
      if(this.sistemaSelected){
      const resp = await this.sitiosTaxonomy(this.sistemaSelected);
      this.sitiosSistema = resp
      }else{
        this.sitiosSistema = []
      }
    },
     async setSistemas(){
      const resp = await this.sistemasTaxonomy();
      this.sistemas = resp
    },
  async editItem(item) {
  this.sitioSistemaClienteId = item.sitioSistemaClienteId;
  this.formEditTitle = "Editar sitio del cliente: ";

  this.sistemaSelected = item.sistemaId;
  this.sitioSelected = item.sitioSistemaId;
  this.clienteNombre = this.$route.params.itemsClientes.clienteNombre;
  this.descripcion = item.descripcion;
  await this.setSitiosSistema()
  this.dialog = true;
},




    newItem(){
      this.formEditTitle = "Nuevo sitio para el cliente: "
      this.clienteNombre = this.$route.params.itemsClientes.clienteNombre
      this.dialog = true;
    },
    deleteItem(item) {
      this.sitioSistemaClienteId = item.sitioSistemaClienteId;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      //await this.deleteSitio(this.sitioSistemaClienteId);
      const res = await this.deleteSitio(this.sitioSistemaClienteId);
      if (res.status == 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.closeDelete();
      }
    },
    close() {
    this.sitioNombre = null;
    this.descripcion = null;
    this.sitioSistemaClienteId = null;
    this.sitioSelected = null;
    this.sistemaSelected = null;
    // Reinicia las reglas de validación
    this.$refs.form.resetValidation();

  this.loadSitios();
  this.dialog = false;
},


    closeDelete() {
      this.dialogDelete = false;
      setTimeout(() => {
        this.loadSitios();
      }, 500);
    },
    async save() {
     let payload = {
      sitioSistemaClienteId: this.sitioSistemaClienteId ?this.sitioSistemaClienteId : 0,
      clienteId: this.clienteId,
      sitioSistemaId: this.sitioSelected,
      descripcion : this.descripcion
      };
     const res = await this.saveSitio(payload);
      if (res.status == 200) {
        this.setAlert({ type: "success", message: "Actualizado con éxito" });
      }
      this.close();
    },
  },
};
</script>
<style scoped>
::v-deep .myLabel {
  color: white;
}
</style>
